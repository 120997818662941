import React from "react"
import { Layout } from '../layout'
import { Helmet } from 'react-helmet'
export default function Menu() {
  return <Layout>
    <Helmet>
      <title>Dinner Menu – Eric's Italian Bistro – Modern & Italian Cuisine</title>
    </Helmet>
    <div className="menu">
      <img className="menu__image" src="/menus/dinner-menu-2025.jpg" alt="Eric's Bistro main menu" />
      <br /><br />
      <p>A 3.5% admin fee is added to credit card transactions</p>
    </div>
  </Layout>
}
